import { Outlet } from 'react-router-dom';
import { Grid, Box, Stack } from '@mui/material';

import logoVKLink from 'assets/logo-identity.svg';
import authBg from 'assets/auth-bg.png';
import { useOriginRedirectUrl } from 'pages/shared/hooks';

const AccountLayout = () => {
  const originRedirectUrl = useOriginRedirectUrl();

  return (
    <Stack
      sx={{
        backgroundImage: {
          xs: 'unset',
          md: `url(${authBg})`,
        },
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: 'primary.main',
        height: '100vh',
        width: '100vw',
        overflow: 'hidden',
      }}
    >
      <Stack
        sx={{
          py: {
            xs: 2,
            sm: 2,
          },
          px: {
            xs: 2,
            sm: 4,
          },
        }}
      >
        <a href={originRedirectUrl}>
          <Box
            component="img"
            sx={{
              height: {
                xs: 50,
                sm: 65,
              },
              objectFit: 'contain',
            }}
            alt="Logo VGS"
            src={logoVKLink}
          />
        </a>
      </Stack>

      <Grid
        container
        sx={{
          flex: 1,
          p: {
            xs: 2,
            sm: 4,
          },
        }}
      >
        <Grid item xs={false} md={7} lg={7}></Grid>

        <Grid
          item
          xs={12}
          md={5}
          sx={{
            height: '80%',
            display: 'flex',
            justifyContent: {
              xs: 'center',
              md: 'flex-start',
            },
            backgroundColor: 'transparent',
          }}
          alignSelf="center"
          borderRadius={1}
        >
          <Box
            sx={{
              borderRadius: 1,
              height: '100%',
              width: '100%',
              flex: 1,
              p: {
                xs: 2,
                md: 4,
              },
              maxWidth: 500,
              backgroundColor: (theme) => theme.palette.common.white,
              opacity: 0.9,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',

              '& > *': {
                flex: 1,
              },
            }}
          >
            <Outlet />
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default AccountLayout;
